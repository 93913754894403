import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: $themeConfig.layout?.isRTL || false,
      skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout?.skin || 'light',
      routerTransition: $themeConfig.layout?.routerTransition || 'fade',
      type: $themeConfig.layout?.type || 'vertical',
      contentWidth: $themeConfig.layout?.contentWidth || 'boxed',
      menu: {
        hidden: $themeConfig.layout?.menu?.hidden || false,
      },
      navbar: {
        type: $themeConfig.layout?.navbar?.type || 'default',
        backgroundColor: $themeConfig.layout?.navbar?.backgroundColor || '#ffffff',
      },
      footer: {
        type: $themeConfig.layout?.footer?.type || 'static',
      },
    },
  },
  getters: {},
  mutations: {
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin || 'light'; // Fallback to 'light' if undefined

      // Update value in localStorage
      localStorage.setItem('vuexy-skin', skin || 'light');

      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList.add('dark-layout');
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout');
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val || 'fade';
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val || 'vertical';
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val || 'boxed';
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val || false;
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj || {});
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj || {});
    },
  },
  actions: {},
}

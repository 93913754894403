<template>
<div class="chat-history">
    <b-sidebar id="sidebar-1" class="" sidebar-class="ch-sidebar" shadow right bg-variant="white" backdrop no-header>
        <template #default="{ hide }">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0 text-white">
                    Chat History
                </h5>

                <div class="ch-close ml-1">
                    <feather-icon class="cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>
            </div>

            <div class="p-1">
                <vue-perfect-scrollbar class="scroll-area">
                    <div class="">
                        <div class="ch-date">
                       
                        </div>
                        <div class="ch-content mb-1" v-for="(chat,index) in chatHistory" :key="index">
                            <div class="d-flex align-items-center" @click='showChatDetails(chat)'>
                                <feather-icon class="cursor-pointer mr-50" icon="MessageSquareIcon" size="16" />
                                <p class="mb-0">{{chat.question}}</p>
                            </div>
                        </div>
                      

                    </div>
                </vue-perfect-scrollbar>
            </div>

        </template>

    </b-sidebar>
</div>
</template>

<script>
import {
    BButton,
    BSidebar,
    VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    components: {
        BButton,
        BSidebar,
        VuePerfectScrollbar,
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },

    props: ['chatHistory'],

    methods: {
        async showChatDetails(chat) {
         

            // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
            var myHeaders = new Headers();
            let tenant_id =JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            // myHeaders.append('x-api-key', api_key);
            var urlencoded = new URLSearchParams();
            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("chat_id", chat.chat_id);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };
            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "getChatHistoryBasedOnChatId",
                requestOptions
            );
            const response = await rawResponse.json();
            if (response.status == true) {
            
                this.$root.$emit('bv::toggle::collapse', 'sidebar-1')
                this.$emit('chatHistoryDetails', response)
            }

        },

    }
}
</script>

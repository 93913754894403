<template>
    <div class="">
    
        <b-sidebar id="chatgpt-sidebar" class="" sidebar-class="sidebar-lg chatgpt-sidebar" bg-variant="white" shadow no-header right v-model='chatVisibility'>
    
            <template #default="{ hide }">
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <div class="">
                        <h4 class="mb-0 text-white chat-bot">
                            {{companyName}} AI Assistant
                        </h4>
                    </div>
    
                    <div class="d-flex">
    
                        <b-dropdown variant="link" no-caret toggle-class="p-0" right class="chat-menu">
    
                            <!-- <template #button-content>
                                <b-button variant="primary" class="btn-icon chat-menu-icon">
                                    <feather-icon icon="MenuIcon" size="16" />
                                </b-button>
                            </template> -->
    
                        </b-dropdown>
    
                        <b-button variant="primary" class="btn-icon chat-close-icon ml-75" @click="closeChat">
                            <feather-icon class="cursor-pointer" icon="XIcon" size="16" />
                        </b-button>
                    </div>
    
                </div>
    
                <!-- Body -->
                <div class="p-0">
    
                    <b-card class="chat-widget mb-0" no-body>
    
                        <section class="chat-app-window">
                            <!-- User Chat Area -->
                            <vue-perfect-scrollbar ref="refChatLogPS" :settings="scrollbar" class="user-chats scroll-area">
    
                                <div class='mb-4'>
                                    <p class='user-name mb-0' :style="{ marginLeft: '20px' }">
                                        Hello {{full_name}} 👋 
                                    </p>
                                </div>
    
                                <chat-log :chat-data="chatData" :profile-user-avatar="tenantProfile" :user-image='profileImageUrl' :fullName='full_name' :companyName='company_name' />
    
                            </vue-perfect-scrollbar>
    
                            <!-- Message Input -->
                            <div class="lt-stage" v-if='chatLoading'>
                                <p class="loading-typing"><span>{{companyName}}</span> Assistant is typing</p>
                            </div>
    
                            <!-- <div class="closed-session" v-if='history' @click='newChat'>
                                <p>New Chat</p>
                            </div> -->
    
                            <!-- Send Enquiry Floating Button -->
<!--     
                            <div class="regenerate-button">
    
                                <b-button variant="light" size="sm" type="submit" class='regenerate-btn' @click='isEmail = true' v-if='isLogg'>
                                    <feather-icon icon="SendIcon" size="14" /> Email to us
                                </b-button>
    
                            </div> -->
    
                            <b-form class="chat-app-form" @submit.prevent="sendMessage">
                                <b-input-group class="input-group-merge form-send-message mr-1">
                                    <b-form-input v-model="chatInputMessage" placeholder="Send a message" />
                                </b-input-group>
    
                                <div class="gpt-rfresh mr-50">
                                <feather-icon
                                :class="{'mic-on': !speaking}"
                                icon="MicIcon"
                                size="16"
                                @mousedown="startPress"
                                @mouseup="stopPress"
                                @touchstart="startPress"
                                @touchend="stopPress"
                                />
                                </div>
    
                                <div class="gpt-rfresh mr-50">
                                    <feather-icon class="cursor-pointer" icon="RefreshCwIcon" size="16" @click='refreshChat' />
                                </div>
    
                                <b-button variant="secondary" type="submit" :disabled='checkSend'>
                                    <feather-icon icon="SendIcon" size="16" />
                                </b-button>
    
                            </b-form>
    
                        </section>
    
                        <!-- Chat Gpt History -->
                        <!-- <chat-gpt-history class="" @chatHistoryDetails="chatHistoryOfUser" :chatHistory='userChatLists' /> -->
    
                    </b-card>
    
                </div>
                <!-- </vue-perfect-scrollbar> -->
    
                <!-- Banner Overlay -->
                <div class="cg-banner" v-if='IsLoggedIn'>
                    <div class="cg-signin">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <p class="mb-0">Please log in to get access to more personalized services.</p>
    
                        </div>
                        <div class="">
                            <b-button class="mr-1" variant="outline-light" @click='continueChat'>Continue As Guest</b-button>
                            <b-button variant="outline-light" v-b-toggle.cg-signin-collapse>Sign In</b-button>
                        </div>
    
                        <!--  <b-collapse id="cg-signin-collapse" class="mt-1"> -->
                            <!-- <b-row>
                                <b-col cols="7" md="8">
                                    <b-form-group label="" label-for="">
                                        <b-form-input placeholder="Email Address" v-model='email_address' />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="5" md="4" class="pl-0">
                                    <b-button variant="secondary" type="" @click='sendOTP'>
                                        {{send_otp}}
                                    </b-button>
                                </b-col> -->
                            <!-- </b-row> -->
    
                            <!-- <b-row v-if='isVerify'>
                                <b-col md="12">
                                    <b-form-group label="" label-for="">
                                        <b-form-input placeholder="Enter Name" v-model='user_name' />
                                    </b-form-group>
                                </b-col>
                            </b-row> -->
    
                            <!-- <b-row v-if='isVerify'> -->
                                <!-- <b-col md="12">
                                    <div class="">
                                        <label class="text-muted lead"> Enter the OTP</label>
                                    </div>
                                </b-col> -->
    
                                <!-- <b-col cols="2" md="2" class="pr-0 otp-inputs" v-for="(field, index) in inputFields" :key="index">
                                    <input :ref="'inputField' + index" @input="handleInput(index)" maxlength="1" class="form-control" v-model="inputFields[index]" />
                                </b-col>
    
                            </b-row> -->
    
                            <b-button class="mt-2" variant="secondary" type="" @click='verifyOTP' v-if='isVerify'>
                                Continue
                            </b-button>
    
                        <!-- </b-collapse> --> 
    
                    </div>
                </div>
    
                <!-- Send E-mail Overlay -->
    
                <div class="cg-send-email-banner" v-if='isEmail'>
                    <div class="">
                        <p class="">Send us an email and one of our team member will get back to you soon.</p>
                    </div>
    
                    <b-row>
                        <b-col md="12" class="mb-75">
                            <label class="text-muted">Select Category / Topic</label>
                            <b-form-select v-model="category" :options="categoryList" />
                        </b-col>
    
                        <b-col md="12" class="mb-1">
                            <label class="text-muted">Enter your message</label>
                            <b-form-textarea placeholder="" rows="4" max-rows="14" class="" v-model='enquiry_message' />
                        </b-col>
    
                        <b-col md="12">
                            <b-form-file v-model="file" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                        </b-col>
    
                        <b-col md="12" class="mt-1">
                            <div class="d-flex align-items-center">
                                <b-button @click="cancelEmail" variant="outline-secondary" type="" class="mr-75">
                                    <feather-icon icon="XIcon" size="16" class="mr-25" />Cancel</b-button>
                                <b-button variant="secondary" type="" @click='insertUpdateSupportTickets'>
                                    <feather-icon icon="SendIcon" size="16" class="mr-25" />Email to us</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
    
            </template>
    
        </b-sidebar>
    
    </div>
    </template>
    
    <script>
    import {
        BLink,
        BFormRadioGroup,
        BFormGroup,
        BFormSelect,
        BFormTextarea,
        BFormFile,
        BFormCheckbox,
        BSidebar,
        BCard,
        BCardHeader,
        BBadge,
        VBToggle,
        BAlert,
        BDropdown,
        BDropdownItem,
        BDropdownDivider,
        BCollapse,
        BPopover,
        VBPopover,
        BCarousel,
        BCarouselSlide,
    } from 'bootstrap-vue'
    import {
        BEmbed
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import ChatLog from './ChatLog.vue'
    import ChatGptHistory from './ChatGptHistory.vue'
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { mapState, mapActions, mapMutations } from 'vuex';
    import { EventBus } from '@/event-bus';
    export default {
        components: {
            // BSV
            BCard,
            BCardHeader,
            BCarousel,
            BCarouselSlide,
            BLink,
            BPopover,
            VBPopover,
            BFormRadioGroup,
            BFormSelect,
            BFormTextarea,
            BFormFile,
            BFormCheckbox,
            BFormGroup,
            BEmbed,
            BSidebar,
            BAlert,
            BDropdown,
            BDropdownItem,
            BDropdownDivider,
            BCollapse,
    
            ChatLog,
            ChatGptHistory,
    
            // 3rd party
            vSelect,
            VuePerfectScrollbar,
            ToastificationContent,
            BBadge
    
        },
    
        directives: {
            'b-toggle': VBToggle,
        },
    
        data() {
            return {
                initializeChat: false,
                chatLoading: false,
                imageUrl: '', 
                company_name: '',
                chatInputMessage: '',
                speechRecognition: new webkitSpeechRecognition(),
                speaking: true,
                pressTimer: null, 
                chatData: [],
                userId: '', 
                IsLoggedIn: false,
                apiRequest: 0,
                email_address: '',
                user_name: '',
                handleInitializePostCreation: null,
                login_id: '',   
                inputFields: Array(4).fill(""),
                isContinue: true,
                checkSend: false,
                enquiry_message: '',
                search_results: [],
                filters: [],
                filter_query: {},
                filteredArray: '',
                category: null,
                file: null,
                categoryList: [{
                        text: "Enquiry",
                        value: 'Enquiry'
                    },
                    {
                        text: "Sales",
                        value: 'Sales'
                    },
                    {
                        text: "Support",
                        value: 'Support'
                    },
                ],
              isEmail: false,
              currentIndex: 0,
              lastEmailSentTime: 0, 
              emailTimeoutId: null,
            }
        },
  
        created() {
        // Bind the listener method to 'this' to maintain context
        console.log('ChatGpt.vue created');
        this.handleInitializePostCreation = this.startNewChatSession.bind(this);
        EventBus.$on('initializePostCreation', this.handleInitializePostCreation);
    },
    beforeDestroy() { 
        console.log('ChatGpt.vue beforeDestroy');
        EventBus.$off('initializePostCreation', this.handleInitializePostCreation);
    },
    
        setup() {
    
            // Perfect Scrollbar
            const scrollbar = {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            }
    
            return {
                // Perfect Scrollbar 
                scrollbar,
            }
        },
    
        mounted() {
    
            this.psToBottom()

        },
        computed: {

            ...mapState({
      chatId: (state) => state.app.chatId,  // Get chatID from Vuex
    //   chatData: (state) => state.app.chatData,  // Get chatData from Vuex
      companyName: (state) => state.app.companyName,
    }),
            chatVisibility: {
      get() {
        return this.$store.state.app.chatVisibility;
      },
      set(value) {
        this.$store.commit('app/SET_CHAT_VISIBILITY', value);
      }
    },
    full_name() {
    const userData = this.$store.state.app.userData;
    if (userData && userData.userName) {
      return userData.userName.trim() !== '' ? userData.userName : 'there';
    }
    return 'there';
  },
            offset() {
                return -this.currentIndex * 100 + '%';
            },
            profileImageUrl() {
    const userData = this.$store.state.app.userData;
    // Check if userData exists and if photoURL exists within userData
    return userData && userData.photoURL ? userData.photoURL : 'default-image-url.jpg'; // Use a default image URL if photoURL is missing
  },
            isLogg() {
                return this.$store.state.app.isLogged
            },
            companyName() {
                return this.$store.state.app.companyName
            },
            tenantProfile() {
                return this.$store.state.app.tenantProfile
            }
    
        },

        watch: {
  chatVisibility(newVal) {
    if (newVal) {
      const chatId = localStorage.getItem('chatId'); // Retrieve chatId from localStorage
      if (chatId) { // Check if chatId exists
        this.loadChatHistory();
      } else {
        console.warn('No chatId found in localStorage. Chat history will not be loaded.');
        // Optionally, handle the case where chatId is missing
      }
    }
  },
},
    
        methods: {
            ...mapMutations('app', ['SET_CHAT_ID', 'SET_CHAT_DATA', 'CLEAR_CHAT']),
          ...mapActions('app', ['syncUserFromStorage']),
   
      startNewChatSession(data) {
        console.log('startNewChatSession called with data:', data);
      const { imageUrl, prompt } = data;

      // Store imageUrl in component's data for later use
      this.imageUrl = imageUrl;
      const updatedPrompt = `create social media posts for ${prompt}`;
      this.prompt = updatedPrompt;

      // Initialize chat session
      
      this.$store.commit("app/SET_CHAT_ID", 0);
      this.$store.commit("app/SET_CHAT_DATA", '');
    //   this.history = false;

      // Set chat visibility to true to display the chat
      this.chatVisibility = true;

      // Add user's initial message to chatData
      this.chatData.push({
        senderID: 2, // Assuming 2 represents the user
        message: updatedPrompt,
        time: new Date(),
        liked: false,
        disliked: false,
      });

      // Scroll to the bottom of the chat
      this.psToBottom();
      // Call fetchDataFromGPT with the prompt
      this.fetchDataFromGPT(updatedPrompt);
      this.imageUrl = '';
    },

      formatChatHistory(history) {
    return history.map((item) => {
      return [
        {
          senderID: 2, 
          message: item.question || '',
          time: new Date(item.timestamp), 
          liked: false, 
          disliked: false,
        },
        {
          senderID: 1,
          message: item.answer || '', // Assistant's response (answer)
          time: new Date(item.timestamp), // Same timestamp for response
          liked: false,
          disliked: false,
        },
      ];
    }).flat(); // Flatten the array since each item produces two messages
  },

  async loadChatHistory() {
  try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}chatHistory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chatId: this.chatId, userId: this.userId }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch chat history");
      }
      const history = await response.json();
      // Format the chat history and append it to chatData
      const formattedHistory = this.formatChatHistory(history);
      this.chatData = [...this.chatData, ...formattedHistory]; // Append formatted history to chatData
  } catch (error) {
    console.error("Error fetching chat history:", error);
  }
  this.psToBottom(); // Scroll the chat to the bottom
},

            handleInput(index) {
                if (index < this.inputFields.length - 1) {
                    this.$refs['inputField' + (index + 1)][0].focus();
                }
            },
            newChat() {
                this.chatData = []
          
                this.$store.commit("app/SET_CHAT_ID", 0);
                this.initializeChat = true;
                this.history = false
            },
    
            cancelEmail() {
                this.isEmail = false
                this.enquiry_message = ''
                this.category = null
                this.file = null
            },
            sendEmail() {
                if (this.isLogg == false) {
                    this.IsLoggedIn = true
                    return false;
                } else {
                    this.isEmail = true
                }
    
            },
            openChatHistory() {
                this.getChatHistoryBasedOnUserId()
                if (this.isLogg == false) {
                    this.IsLoggedIn = true
                    return false;
                } else {
                    this.$root.$emit('bv::toggle::collapse', 'sidebar-1')
                }
    
            },
    
            async insertUpdateSupportTickets() {
    
                if (this.category == "" || this.category == null) {
    
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Please select category",
                            icon: 'AlertCircleIcon',
                            variant: 'warning'
                        },
                    })
                    return false;
                } else if (this.enquiry_message == "" || this.enquiry_message == null) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Please enter your message",
                            icon: 'AlertCircleIcon',
                            variant: 'warning'
                        },
                    })
                    return false;
                } else {
    
                    var myHeaders = new Headers();
                  //   const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                    let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;                 
                  //   myHeaders.append('x-api-key', api_key);
    
                    const formData = new FormData();
                    const userData = localStorage.getItem("userData")
                    if (userData == null || undefined) {
                        this.userId = null
                    } else {
                        this.userId = JSON.parse(localStorage.getItem("userData")).userId;
                    }
    
                    formData.append("tenant_id", tenant_id);
                    formData.append("userId", this.userId);
                    formData.append("question", this.enquiry_message);
                    formData.append("support_category", this.category);
                    formData.append("uploads[]", this.file);
                    formData.append("chatId", this.$store.state.app.chatId);
                    var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: formData,
                        redirect: "follow",
                    };
    
                    const rawResponse = await fetch(
    
                        process.env.VUE_APP_BASE_URL + "insertUpdateTicket",
    
                        requestOptions
                    );
    
                    const response = await rawResponse.json();
    
                    if (response.data[0].status == 1) {
    
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Thank you! We recieved your email and will contact you soon.",
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                        this.isEmail = false;
                        this.enquiry_message = ''
                        this.category = ''
                
                        // this.$store.commit("app/SET_CHAT_ID", 0);
                    } else if (response.data[0].status == 0) {
    
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Oops, something went wrong, please try again.",
                                icon: 'AlertCircleIcon',
    
                                variant: 'danger'
                            },
                        })
                        this.isEmail = false;
                        this.enquiry_message = ''
                        this.category = ''
                  
                        // this.$store.commit("app/SET_CHAT_ID", 0);
                    }
                }
    
            },
            continueChat() {
                this.IsLoggedIn = false
                this.isContinue = false
            },
    
    
            closeChat() {
        
                this.history = false;
                this.$root.$emit('bv::toggle::collapse', "chatgpt-sidebar");
                this.$store.commit("app/SET_INITIALIZE_CHAT", false);
                this.initializeChat = false;
                this.IsLoggedIn = false;
                this.user_name = '';
                    this.email_address = '';
                this.chatInputMessage = '';
                this.inputFields = Array(4).fill("");
                this.enquiry_message = '';
                this.category = null;
                this.file = null;
                this.isVerify = false;
                this.chatLoading = false;
                this.checkSend = false;
  
               // Clear any existing timeout to avoid multiple emails
               clearTimeout(this.emailTimeoutId);    
            },

  
            refreshChat() {
                this.chatData = []
                this.chatLoading = false
                this.checkSend = false
                this.$store.commit("app/SET_CHAT_ID", 0);
                this.chatInputMessage = '';
                // this.$store.commit("app/SET_CHAT_DATA", []);
            },
    
            //fetching chatGPT results//

  async fetchDataFromGPT(val) {
    this.chatInputMessage = '';
    this.chatLoading = true;
    this.checkSend = true;

    try {
      // Retrieve user and domain data
      const userData = JSON.parse(localStorage.getItem('userData')) || {};
      const domainData = JSON.parse(localStorage.getItem('domain_Data')) || {};

      // Extract needed data
      const userId = userData.userId || null;
      const tenant_id = domainData.tenant_id;

      // Prepare headers
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      myHeaders.append('Accept', 'text/event-stream');

      // Prepare URL-encoded body
      const urlencoded = new URLSearchParams();
      urlencoded.append('tenant_id', tenant_id);
      urlencoded.append('userId', userId);
      urlencoded.append('question', val);
      urlencoded.append('chatId', this.$store.state.app.chatId);
      urlencoded.append('query_type', 'assistant');

      // Include imageUrl if needed by the API
      if (this.imageUrl) {
        urlencoded.append('imageUrl', this.imageUrl);
      }

      // Make request to the API
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow',
      };

      const rawResponse = await fetch(
        `${process.env.VUE_APP_BASE_URL}assistant`,
        requestOptions
      );

      if (!rawResponse.ok) {
        throw new Error('Network response was not ok');
      }

      // Prepare new message placeholder in chatData
      let currentMessage = {
        senderID: 1, // Assuming 1 represents the assistant
        message: '',
        time: new Date(),
        liked: false,
        disliked: false,
      };
      let chatIndex = this.chatData.length;
      this.chatData.push(currentMessage);

      // Process streaming response
      const reader = rawResponse.body.getReader();
      for (;;) {
        const { value, done } = await reader.read();

        if (done) {
          break;
        }

        setTimeout(() => {
          const chunk = new TextDecoder().decode(value);
          const regex = /{{~([a-zA-Z0-9]+)~}}/;
          const match = chunk.match(regex);

          // Update chatId in Vuex if found in the chunk
          if (match) {
            this.$store.commit('app/SET_CHAT_ID', match[1]);
            this.$store.commit('app/SET_LIKE_DISLIKE_ID', this.$store.state.app.chatId);
          }

          // Append chunk to the message
          let messageContent = this.chatData[chatIndex].message;
          const modifiedSentence = chunk.replace(/{{~[a-zA-Z0-9]+~}}/g, '');
          this.chatData[chatIndex].message = messageContent + modifiedSentence;
          this.psToBottom();
        }, 100);
      }
    } catch (error) {
      console.error('Error fetching chat data:', error);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Oops, something went wrong!',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      });
    } finally {
      // Reset loading states
      this.chatLoading = false;
      this.checkSend = false;
    }
  },

            //Sending Prompt to chatGPT//
            async sendMessage() {
                const userData = localStorage.getItem("userData")
                this.apiRequest++
    
                if (this.chatInputMessage == '' || this.chatInputMessage == null) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please enter your message',
    
                            icon: 'AlertCircleIcon',
    
                            variant: 'warning'
                        },
                    })
                    return false;
                } else if (userData == null && this.apiRequest == 3 && this.isContinue) {
                    this.IsLoggedIn = true
    
                    this.chatLoading = false
                    return false
    
                } else {
    
                    this.chatData.push({
                        senderID: 2,
                        message: this.chatInputMessage,
                        time: new Date(),
                        liked: false,
                        disliked: false
                    })
                    this.psToBottom(); 
        // localStorage.setItem('chatHistory', JSON.stringify(this.chatData));
                    setTimeout(() => {
                      this.fetchDataFromGPT(this.chatInputMessage);
                  }, 50);
                }
            },
    
            //Using the webkitSpeechRecognition API to listen to the user's voice
            speechToText() {
    
                let final_transcript = "";
    
                this.speechRecognition.continuous = true;
                this.speechRecognition.interimResults = true;
                this.speechRecognition.start();
                this.speaking = false;
                // Event listener for the "result" event
                this.speechRecognition.onresult = (event) => {
                    const transcript = event.results[event.results.length - 1][0].transcript;
                    this.chatInputMessage = transcript;
                };
    
            },
            startPress() {
      // Clear any existing timer
      if (this.pressTimer) clearTimeout(this.pressTimer);

      // Start the press timer (500ms for a long press)
      this.pressTimer = setTimeout(() => {
        this.speechToText();  // Trigger the long press action
      }, 500); // You can adjust this delay (500ms)
    },
    stopPress() {
      // Clear the timer if the press duration is less than 500ms
      if (this.pressTimer) clearTimeout(this.pressTimer);
      
      this.speechToTextStop();  // Stop speech-to-text on release
    },


            initializeSSE() {
        // EventSource connects to your server endpoint that sends SSE responses
        const eventSource = new EventSource(process.env.VUE_APP_BASE_URL + "assistant");

        eventSource.onmessage = (event) => {
            const serverMessage = event.data;

            // Check if it's a progress update (like "Meme/Image generation in progress...")
            if (serverMessage.includes('in progress')) {
                this.chatLoading = true;
            }

            // Stop the typing indicator once generation is complete
            if (serverMessage.includes('generation complete')) {
                this.chatLoading = false;
            }

            // Push the response to the chatData array as a new chat message
            const chatMessage = {
                senderID: 1,  // 1 for AI assistant response
                message: serverMessage,
                time: new Date(),
                liked: false,
                disliked: false,
            };

            // Append the SSE message as a chat input
            this.chatData.push(chatMessage);

            // Auto-scroll to the bottom of the chat window
            this.psToBottom();
        };

        eventSource.onerror = (error) => {
            console.error("Error with SSE connection:", error);
            eventSource.close(); // Optionally close the SSE connection on error
        };
    },

    psToBottom() {
  this.$nextTick(() => {
    const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS;
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  });
},
    
     async speechToTextStop() {
      // Stop speech recognition
      this.speechRecognition.stop();
      this.speaking = true;
  
      // Listen for the 'end' event to ensure the recognition has fully stopped
      this.speechRecognition.onend = async () => {
        await this.sendMessage();
      };
    },
    
        }
    }
    </script>
    
    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import "@core/scss/base/pages/app-chat-list.scss";
    </style><style lang="scss" scoped>
    @import '~@core/scss/base/bootstrap-extended/include';
    @import '~@core/scss/base/components/variables-dark';
    
    .slider {
        display: flex;
        overflow: hidden;
        margin: 20px 0;
    }
    
    .slides {
        display: flex;
        transition: transform 0.5s ease-in-out;
    }
    
    .slide {
        min-width: 33.33%;
        box-sizing: border-box;
        margin-right: 2px;
    }
    
    .slide img {
        width: 100%;
        height: auto;
        margin-right: 5px;
    }
    
    .controls {
        margin-top: 10px;
        margin-left: 155px;
    }
    
    .control-button {
        background-color: #333;
        color: #fff;
        padding: 10px;
        margin: 0 5px;
        cursor: pointer;
        border: none;
    }
    
    .btn {
        cursor: pointer;
        color: white;
    }
    
    .btn:hover {
        color: black;
    }
    
    /* Define the animation */
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
    
        100% {
            opacity: 1;
        }
    
    }
    
    /* Apply the animation to the element */
    .fade-in-element {
        animation: fadeIn 2s ease-in-out;
        /* Adjust the duration and easing as per your preference */
    }
    
    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    
    /* Style for each item */
    .item {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: center;
        margin-top: 5px;
        margin-left: 3px;
    }
    
    /* Style for the images */
    .item img {
        max-width: 100%;
        height: auto;
    }
    
    .customizer-section {
        padding: 1.5rem;
        border-bottom: 1px solid #ebe9f1;
    
        .dark-layout & {
            border-color: $theme-dark-border-color;
        }
    
        #skin-radio-group ::v-deep {
            .custom-control-inline {
                margin-right: 0.7rem;
            }
        }
    
        .form-group {
            margin-bottom: 1.5rem;
            ;
    
            &:last-of-type {
                margin-bottom: 0;
            }
    
            ::v-deep legend {
                font-weight: 500;
            }
        }
    }
    
    .mark-active {
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, .5);
    }
    
    .mic-on {
        color: red;
    }
    
    .closed-session {
        text-align: center;
        color: white;
        background: black;
        cursor: pointer
    }
    
    .ps-customizer-area {
        height: calc(100% - 83px)
    }
    
    // .chat-bot{
    //     position:relative;
    //     left:75px;
    // }
    .user-name {
        position: absolute;
        margin-left: 52px;
        left: -100%;
        /* Start the string off the left side of the container */
        top: 5%;
        transform: translateY(-50%);
        white-space: nowrap;
        /* Prevent the string from wrapping to the next line */
        animation: appearString 1s forwards;
        color: #fff;
        /* Animation properties */
    }
    
    .cg-alert {
        padding: 0.5rem 0.5rem !important;
        font-size: 11px !important;
        font-weight: 600 !important;
        margin-bottom: 0;
        z-index: 1;
    
    }
    
    .cg-alert.alert-warning {
        // background: rgba(255, 159, 67) !important;
        background-color: #dc3545 !important;
        color: #fff !important;
        border: 1px solid #dc3545 !important;
    }
    
    // .cg-alert.alert-dismissible .close{
    //     padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
    // }
    
    @keyframes appearString {
        0% {
            left: -100%;
            /* Starting position */
            opacity: 0;
            /* Starting opacity */
        }
    
        100% {
            left: 0;
            /* Ending position (center of the container) */
            opacity: 1;
            /* Ending opacity */
        }
    }
    
    .product-categories {
        cursor: pointer;
    }
    
    .otp-input-container {
        display: flex;
        align-items: center;
        gap: 8px;
        /* Adjust the gap between input fields */
    }
    
    .otp-input-field {
        width: 24px;
        /* Adjust the width of the input fields */
        height: 24px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
    }
    </style>